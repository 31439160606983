<template>
	<div>
		<cashflowTicket />
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoaderProduct">
			<div class="row pt10">
				<div class="col-md-12 text-center">
					<div class="row">
						<div class="col-4">
							<a-button class="h60 btn btn-block btn-info" :disabled="hasCart" @click="generateCashflow">
								<i class="fa fa-2x fa-save"></i>
							</a-button>
							<br />
							<small>Generar corte</small>
						</div>
						<!-- <div class="col-4">
							<a-button class="h60 btn btn-block btn-success" :disabled="!hasCart" @click="showModalDiscount">
								<i class="fa fa-2x fa-money"></i>
							</a-button>
							<br />
							<small>Aplicar Descuento</small>
						</div> -->
						<!-- <div class="col-4">
							<a-button class="h60 btn btn-block btn-rls" :disabled="!hasCart" @click="onShowModal('payment', 'pacific')">
								<i class="fa fa-2x fa-dollar"></i>
							</a-button>
							<br />
							<small>Cobrar</small>
						</div> -->
						<!-- <div class="col-4">
							<a-button class="h60 btn btn-block btn-rls" :disabled="!hasCart" @click="onShowModal('payment', 'rls')">
								<i class="fa fa-2x fa-dollar"></i>
							</a-button>
							<br />
							<small>Cobrar</small>
						</div> -->
						<!-- <div class="col-3">
							<a-button class="h60 btn btn-block btn-purple" :disabled="!hasCart" @click="onShowModal('payment', 'panel')">
								<i class="fa fa-2x fa-dollar"></i>
							</a-button>
							<br />
							<small>Cobrar</small>
						</div> -->
						<!-- <div class="col-4">
							<a-button class="h60 btn btn-block btn-danger" @click="onCancel" :disabled="!hasCart">
								<i class="fa fa-2x fa-times"></i>
							</a-button>
							<br />
							<small>Cancelar</small>
						</div> -->
					</div>
				</div>
			</div>
			<div class="row" v-if="false">
				<div class="col-md-4">
					<b>Tipo de venta</b>
					<a-select style="width: 100%" v-model="customer_type" :disabled="hasCart">
						<a-select-option value="general">Público General</a-select-option>
						<a-select-option value="supplier">Distribuidor</a-select-option>
						<a-select-option value="s2r">S2R</a-select-option>
					</a-select>
				</div>
				<div class="col-md-8 pt20">
					<div class="pull-right">
						<a-button class="btn btn-info" @click="addShipment" :disabled="!hasCart"> <i class="fa fa-plus"></i> Añadir gastos de envío </a-button>
					</div>
				</div>
			</div>
			<div class="row" v-if="false && !setAdvance">
				<div class="col-md-12 pt20">
					<a-input-search v-model="searchText" placeholder="Ingresa texto o SKU para buscar" enter-button @search="onSearch" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<hr class="hrDotted" />
					<div class="row" v-if="hasClient">
						<div class="col-md-12 text-left" v-if="hasClient">
							<b>== Datos del cliente ==</b>
							<!-- <small class="pointer" :style="{ color: 'red' }" @click="onCleanClient">Eliminar</small> -->
							<br />
							<small>
								<b>Nombre:</b> {{ client.full_name }} {{ client.client_last_surname | replaceIfLogicalFalse('') }}<br />
								<b>Email:</b> {{ client.email | replaceIfLogicalFalse('- No establecido -') }} || <b>Teléfono de contacto:</b> {{ client.phone }} <br />
							</small>
						</div>
					</div>
					<div class="row" v-if="hasCart">
						<div class="col-md-6 text-left">
							<h1 class="text-muted">{{ `${totalProducts} producto${totalProducts == 1 ? '' : 's'}` }}</h1>
							<h5 class="text-muted" v-if="Number(serviceOrder) > 0">
								Orden de Servicio: {{ serviceOrder }}
								<i class="fa fa-times pointer" style="color: red" @click="onDeleteServiceOrder"></i>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<h1 class="text-muted">Total: {{ numeral(total - priceAdjustment - totalAdvances - orderDiscount).format('$0,0.00') }}</h1>
							<h5 class="text-muted" v-if="totalAdvances > 0 || priceAdjustment > 0">
								<b>Total:</b> {{ numeral(total).format('$0,0.00') }}
								<span v-if="totalAdvances > 0"> | <b>Anticipos:</b> {{ numeral(totalAdvances).format('$0,0.00') }} </span>
								<span v-if="priceAdjustment > 0">
									| <b>Ajuste:</b> {{ numeral(priceAdjustment).format('$0,0.00') }}
									<i class="fa fa-times pointer" style="color: red" @click="onDeletePriceAdjustment"></i>
								</span>
							</h5>
							<div class="text-muted" v-if="advances.length > 0">
								<div v-for="(advance, i) in advances" :key="i">
									Anticipo ticket #{{ advance.ticket }} con fecha {{ moment(advance.created_at).format('DD-MM-YYYY') }} por {{ numeral(advance.total).format('$0,0.00') }}
									<i class="fa fa-times pointer" style="color: red" @click="onDeleteAdvance(i)"></i>
								</div>
							</div>
							<div class="text-muted" v-if="orderDiscount">
								Aplicando descuento por {{ numeral(orderDiscount).format('$0,0.00') }}
								<hr class="hrDotted" />
								<div v-if="discountsByArea.boutique">
									Descuento por piso de venta: {{ numeral(discountsByArea.boutique).format('$0,0.00') }} <i class="fa fa-times pointer" style="color: red" @click="onDeleteDiscount('boutique')"></i>
								</div>
								<div v-if="discountsByArea.servicio">
									Descuento por servicio: {{ numeral(discountsByArea.servicio).format('$0,0.00') }} <i class="fa fa-times pointer" style="color: red" @click="onDeleteDiscount('servicio')"></i>
								</div>
								<div v-if="discountsByArea.refacciones">
									Descuento por refacciones: {{ numeral(discountsByArea.refacciones).format('$0,0.00') }}
									<i class="fa fa-times pointer" style="color: red" @click="onDeleteDiscount('refacciones')"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="pt10">
						<a-table :columns="columns" :dataSource="cart" :pagination="false" v-if="hasCart">
							<div slot="product" slot-scope="record">
								{{ record.label }}
								<br />
								<small><b>SKU</b>: {{ record.product.sku | replaceIfLogicalFalse('-') }}</small>
							</div>
							<div slot="sale_price_label" slot-scope="record">
								{{ record.amount_label }}
							</div>
							<div slot="action" slot-scope="record">
								<!-- <a-button class="ml5 btn btn-warning" size="small" icon="edit" v-if="!setAdvance" @click="onChangePrice(record.id)" /> -->
								<a-button class="ml5 btn btn-danger" size="small" icon="delete" @click="onDeleteProduct(record.id)" />
							</div>
						</a-table>
					</div>
				</div>
			</div>
			<!-- Listado de Productos -->
			<a-modal :visible="modal.products.visible" title="Listado de Productos" :closable="true" @cancel="onCloseModal('products')" width="80%">
				<template slot="footer">
					<a-button key="back" @click="onCloseModal('products')"> Cerrar </a-button>
				</template>
				<productsListComponent @selectedProduct="onSelectProduct" />
			</a-modal>
			<!-- Búsqueda de cliente -->
			<a-modal :visible="modal.client.visible" title="Búsqueda de Cliente" :closable="false" width="60%">
				<template slot="footer">
					<a-button key="back" @click="onCloseModal('client')"> Cancelar </a-button>
					<a-button key="submit" class="btn btn-success" @click="addClient"> Seleccionar </a-button>
				</template>
				<clientsQuickSearchComponent ref="clientsQuickSearchComponent" :visible="modal.client.visible" :showAddButton="false" @selectedRecord="onSelectedClient" @addClient="onAddClient" />
			</a-modal>
			<!-- Modal de pago -->
			<a-modal :visible="modal.payment.visible" title="Pago" :closable="false" width="70%">
				<template slot="footer">
					<a-button key="back" @click="ticketData.id ? closePayment() : onCloseModal('payment')" :loading="spinnerLoader"> Cerrar </a-button>
					<a-button class="btn btn-info" @click="printTicket" v-if="ticketData.id" :loading="spinnerLoader"> Imprimir ticket </a-button>
					<a-button key="submit" class="btn btn-success" v-if="!ticketData.id" @click="checkOut" :loading="spinnerLoader"> Cobrar </a-button>
				</template>
				<paymentComponent
					ref="paymentComponent"
					:editable="ticketData.id || disabledFields ? true : false"
					:visible="modal.payment.visible"
					:total="total"
					:priceAdjustment="priceAdjustment + orderDiscount"
					:advance="totalAdvances"
					:client="client"
					:posFrom="posFrom"
					@closeSale="onCloseSale"
					@setCreditSale="onShowModal('client')"
				/>
			</a-modal>
			<!-- Edición de precios particular -->
			<a-modal :visible="modal.adjust.visible" title="Editar detalles del producto/servicio" :closable="false" width="40%">
				<template slot="footer">
					<a-button key="back" @click="onCloseModal('adjust')"> Cancelar </a-button>
					<a-button key="submit" class="btn btn-success" @click="adjustProduct"> Siguiente </a-button>
				</template>
				<div class="row">
					<div class="col-md-6">
						Cantidad
						<a-input prefix="#" v-mask="'####'" autocomplete="off" v-model="selectedProduct.quantity" v-on:keyup.enter="adjustProduct" :disabled="setAdvance" />
					</div>
					<div class="col-md-6">
						Precio unitario
						<a-input prefix="$" autocomplete="off" v-model="selectedProduct.normal_price" :disabled="true" />
					</div>
					<div class="col-md-4" v-if="false">
						Nuevo precio
						<a-input prefix="$" autocomplete="off" v-model="selectedProduct.sale_price" v-on:keyup.enter="adjustProduct" />
					</div>
					<div class="col-md-12 pt10" v-if="setAdvance">
						Descripción
						<a-input autocomplete="off" v-model="selectedProduct.label" v-on:keyup.enter="adjustProduct" />
					</div>
				</div>
			</a-modal>
			<!-- Registro de clientes -->
			<a-modal :visible="modal.addClient.visible" title="Añadir cliente" :closable="true" width="70%">
				<template slot="footer">
					<a-button key="back" @click="onCloseModal('addClient')"> Cancelar </a-button>
					<a-button key="submit" class="btn btn-success" @click="onCreateClient"> Añadir </a-button>
				</template>
				<clientsDetailGeneralComponent :showControls="false" ref="clientsDetailGeneral" @selectedRecord="onSelectedClient" />
			</a-modal>
		</a-spin>
		<!-- TICKET -->
		<div id="posTicket" align="center" v-show="false">
			<table border="0" width="450">
				<tr v-if="ticketData.from_rls">
					<td align="center" colspan="3">
						<div class="txt1" style="padding-top: 5mm">
							STEEL BACKBONE<br />
							SBA230523F69
						</div>
						<div class="txt2">
							Hidalgo #196<br />
							Col. Romero, Nezahualcóyotl,<br />
							Estado de México, C.P 57630
						</div>
						<div class="txt3">Tel. 33 3027 4668 || Email: atc@shieldmoto.mx</div>
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr v-else>
					<td align="center" colspan="3">
						<div class="txt1" style="padding-top: 5mm">Steel Backbone</div>
						<div class="txt2">Comprobante de Pago</div>
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr>
					<td width="33%" align="center" class="txt2">
						<b>Ticket</b>
					</td>
					<td width="33%" align="center" class="txt2">
						<b>Fecha</b>
					</td>
					<td width="33%" align="center" class="txt2">
						<b>Hora</b>
					</td>
				</tr>
				<tr>
					<td width="33%" align="center" class="txt3">#{{ ticketData.id }}</td>
					<td width="33%" align="center" class="txt3">{{ moment(ticketData.created_at).format('DD-MM-YYYY') }}</td>
					<td width="33%" align="center" class="txt3">{{ moment(ticketData.created_at).format('HH:mm:ss') }} hrs.</td>
				</tr>
				<tr>
					<td v-if="Number(serviceOrder) > 0" align="center" class="txt2">{{ serviceOrder }}</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">=========================================</div>
					</td>
				</tr>
			</table>
			<table border="0" width="450">
				<tr v-for="(record, index) in cart" :key="index">
					<td align="left" class="txt3 p07">
						{{ record.label }}
						<br />
						<small><b>SKU</b>: {{ record.product.sku | replaceIfLogicalFalse('-') }}</small>
						<small v-if="record.wc_discount">
							<br />
							<b>Promoción</b>: -{{ record.wc_discount }}%
						</small>
						<br />
						<div v-if="record.wc_discount">
							<span style="text-decoration: line-through">{{ numeral(getPrice(record.product.prices)).format('$0,0.00') }}</span>
							<span> | {{ record.sale_price_label }}</span>
						</div>
						<b v-else>{{ record.amount_label }}</b>
					</td>
				</tr>
			</table>
			<table border="0" width="450">
				<tr v-if="!orderDiscount">
					<td align="right" class="txt3 p07"><b>Total:</b> {{ numeral(total).format('$0,0.00') }}</td>
				</tr>
				<tr v-else>
					<td align="right" class="txt3 p07"><b>Subtotal:</b> {{ numeral(total).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="orderDiscount">
					<td align="right" class="txt3 p07"><b>Descuento:</b> {{ numeral(orderDiscount).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="orderDiscount">
					<td align="right" class="txt3 p07"><b>Total:</b> {{ numeral(total - orderDiscount).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="totalAdvances > 0">
					<td align="right" class="txt3 p07"><b>Anticipo:</b> {{ numeral(totalAdvances).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="center" class="txt2 pt10">
						<b>=== Formas de Pago ===</b>
					</td>
				</tr>
				<tr v-if="numeral(paymentData?.cash).value() > 0">
					<td align="right" class="txt3 p07"><b>Efectivo:</b> {{ numeral(paymentData?.cash).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="numeral(paymentData?.bank_terminal).value() > 0">
					<td align="right" class="txt3 p07"><b>Tarjetas:</b> {{ numeral(paymentData?.bank_terminal).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="numeral(paymentData?.billpocket).value() > 0">
					<td align="right" class="txt3 p07"><b>Tarjetas (BillPocket):</b> {{ numeral(paymentData?.billpocket).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="numeral(paymentData?.transfer).value() > 0">
					<td align="right" class="txt3 p07"><b>Transferencia:</b> {{ numeral(paymentData?.transfer).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="numeral(paymentData?.paypal).value() > 0">
					<td align="right" class="txt3 p07"><b>PayPal:</b> {{ numeral(paymentData?.paypal).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="numeral(paymentData?.mercadopago).value() > 0">
					<td align="right" class="txt3 p07"><b>MercadoPago:</b> {{ numeral(paymentData?.mercadopago).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="numeral(paymentData?.aplazo).value() > 0">
					<td align="right" class="txt3 p07"><b>Aplazo:</b> {{ numeral(paymentData?.aplazo).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Cambio:</b> {{ numeral(paymentData?.change).format('$0,0.00') }}</td>
				</tr>
				<tr v-if="setAdvance" class="txt2 pt10">
					<td align="center" class="txt3 p07">
						<b>*** ESTE COBRO ES UN ANTICIPO ***</b>
					</td>
				</tr>
			</table>
			<table border="0" width="450" v-if="hasBankTerminalOperations">
				<tr>
					<td colspan="2" align="center">
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr v-for="(element, index) in paymentData?.bankTerminalOperations" :key="index">
					<td width="50%" align="center" class="txt3">
						<b></b>
						{{ element.cardHolder.toUpperCase() }} {{ element.ending }}
					</td>
					<td width="50%" align="center" class="txt3">
						<b>Aprobación</b>
						{{ element.approval }}
					</td>
				</tr>
			</table>
			<table border="0" width="450" v-if="ticketData.from_rls">
				<tr>
					<td align="center">
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr>
					<td align="center" class="txt2">
						<b>=== Cliente ===</b>
					</td>
				</tr>
			</table>
			<table border="0" width="450" v-if="ticketData.from_rls && hasClient">
				<tr>
					<td align="left" class="txt3 p07">
						<b>Nombre:</b> {{ client.full_name }}<br />
						<b>Email:</b> {{ client.email | replaceIfLogicalFalse('- No establecido -') }}<br />
						<b>Teléfono de contacto:</b> {{ client.phone }}
					</td>
				</tr>
			</table>
			<table border="0" width="450" v-else>
				<tr>
					<td align="center" class="txt3">Público en General</td>
				</tr>
			</table>
			<table border="0" width="450" v-if="ticketData.from_rls">
				<tr>
					<td align="center" class="p07">
						<br />
						<b class="txt2">=== Facturación ===</b><br />
						<div class="txt3">
							Recuerda que tienes hasta el último día del mes para generar tu factura y podrás hacerlo en sucursal con ticket de compra vigente del mes en curso y los datos fiscales con respecto a la
							versión 4.0 del uso de CFDI o a través de nuestra página web: <b>https://facturacion.shieldmoto.mx</b> con los siguientes datos:
							<br />
							<br />
							<b>Ticket:</b> {{ ticketData.id }} <br />
							<b>Validador:</b> {{ ticketData.validator }}
						</div>
					</td>
				</tr>
			</table>
			<table border="0" width="450" v-if="ticketData.from_rls">
				<tr>
					<td align="center">
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr>
					<td align="center" class="txt3 p07">Agradecemos tu preferencia.</td>
				</tr>
				<tr>
					<td align="center" class="txt3 p07">Consulta nuestro aviso de privacidad en <b>https://shieldmoto.mx/aviso-de-privacidad</b></td>
				</tr>
				<tr>
					<td align="center" class="txt3 p07">
						Nuestros productos y servicios cuentan con 30 días de garantía en tienda.
						<br />
						Aplican restricciones
						<br />
						Consúltalo en <b>https://shieldmoto.mx/garantias</b>
					</td>
				</tr>
			</table>
		</div>
		<!-- Listado de Productos -->
		<a-modal :visible="modal.serviceOrders.visible" title="Órdenes de Servicio" :closable="true" @cancel="onCloseModal('serviceOrders')" width="50%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('serviceOrders')"> Cerrar </a-button>
				<a-button key="submit" class="btn btn-success" @click="getOutboundOrder"> Confirmar </a-button>
			</template>
			<div class="row">
				<div class="col-md-2 text-center pt10" v-for="(element, index) in serviceOrdersList" :key="index">
					<a-button :class="serviceOrderSelected == element.id ? 'btn btn-success' : ''" @click="onSelectServiceOrder(element.id)" style="font-size: 22px">{{ element.id }}</a-button>
				</div>
			</div>
		</a-modal>
		<!-- Listado de Productos -->
		<a-modal :visible="modal.discounts.visible" title="Descuentos" :closable="true" @cancel="onCloseModal('discounts')" width="50%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('discounts')"> Cerrar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onSetDiscount"> Confirmar </a-button>
			</template>
			<discountComponent :visible="modal.discounts.visible" @ok="addDiscount" />
		</a-modal>
	</div>
</template>

<script>
//
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import _ from 'lodash'
import { clientModel } from '@/constants'
import utilities from '@/services/utilities'
import { VMoney } from 'v-money'
import { mask } from 'vue-the-mask'
import moment from 'moment'
import VueHtmlToPaper from 'vue-html-to-paper'
import { cashFlowModel } from '@/constants'
//
import productsListComponent from '../productsList'
import clientsQuickSearchComponent from '@/components/clients/quickSearch'
import paymentComponent from '@/components/payment'
import clientsDetailGeneralComponent from '@/components/clients/detail/partials/general'
import cashflowTicket from '@/components/cashflowTicket'
import discountComponent from '@/components/pos/discounts'

const options = {
	name: '_blank',
	specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
	styles: ['resources/css/printTickets.css'],
	timeout: 1000, // default timeout before the print window appears
	autoClose: true, // if false, the window will not close after printing
	windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

export default {
	name: 'posComponent',
	components: {
		productsListComponent,
		clientsQuickSearchComponent,
		paymentComponent,
		clientsDetailGeneralComponent,
		cashflowTicket,
		discountComponent,
	},
	directives: {
		money: VMoney,
		mask,
	},
	computed: {
		...mapGetters('products', ['productsList', 'actualOutboundOrder', 'spinnerLoaderLabel']),
		...mapGetters('warehouse', ['spinnerLoader']),
		...mapGetters('users', ['usersList']),
		...mapGetters('user', ['user']),
		...mapGetters('serviceOrders', ['serviceOrdersList']),
		...mapGetters('customers', ['customersList']),
		spinnerLoaderProduct() {
			return this.$store.state.products.spinnerLoader
		},
		hasCart() {
			return this.cart.length == 0 ? false : true
		},
		hasClient() {
			return !!utilities.objectValidate(this.client, 'full_name', false)
		},
		theSalesman() {
			return `${this.salesman.name} ${this.salesman.first_lastname}`
		},
		total() {
			let summ = 0
			this.cart.forEach((e) => {
				summ += e.amount
			})
			return summ
		},
		totalProducts() {
			let summ = 0
			this.cart.forEach((e) => {
				summ += numeral(e.quantity).value()
			})
			return numeral(summ).value()
		},
		theCart() {
			let cart = this.cart
			if (cart.length) {
				cart = cart.reverse()
			} else {
				cart = []
			}
			return cart
		},
		totalAdvances() {
			if (this.advances.length > 0) {
				let total = 0
				this.advances.forEach((element) => {
					total += element.total
				})
				return total
			}
			return 0
		},
		disabledFields() {
			return this.$store.state.warehouse.disabled
		},
		hasBankTerminalOperations() {
			return !!utilities.objectValidate(this.paymentData, 'bankTerminalOperations', false)
		},
		orderDiscount() {
			return numeral(this.discountsByArea.boutique + this.discountsByArea.servicio + this.discountsByArea.refacciones).value()
		},
		boutiqueCashflow() {
			return [1, 41, 45].includes(this.user.user_id)
		},
	},
	data() {
		return {
			customer_type: '',
			localData: _.cloneDeep(cashFlowModel.cashFlowStructure),
			serviceOrderSelected: '',
			searchText: '',
			columns: [
				{
					title: 'Producto',
					scopedSlots: { customRender: 'product' },
					width: '55%',
				},
				{
					title: 'Monto',
					scopedSlots: { customRender: 'sale_price_label' },
					width: '10%',
					align: 'right',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			],
			cart: [],
			client: _.cloneDeep(clientModel),
			salesman: {},
			selectedProduct: {},
			modal: {
				boutique: {
					visible: false,
				},
				products: {
					visible: false,
				},
				quickSale: {
					visible: false,
				},
				client: {
					visible: false,
				},
				adjust: {
					visible: false,
				},
				payment: {
					visible: false,
				},
				addClient: {
					visible: false,
				},
				serviceOrders: {
					visible: false,
				},
				discounts: {
					visible: false,
				},
			},
			ticketData: {},
			paymentData: {},
			priceAdjustment: 0,
			serviceOrder: 0,
			setAdvance: false,
			advances: [],
			totalByArea: {
				boutique: 0,
				servicio: 0,
				refacciones: 0,
			},
			discountsByArea: {
				boutique: 0,
				servicio: 0,
				refacciones: 0,
			},
			posFrom: 'rls',
		}
	},
	mounted() {
		this.salesman = _.cloneDeep(this.user)
	},
	destroyed() {
		this.cleanData()
	},
	methods: {
		numeral,
		moment,
		// onSaveRecord() {
		// 	let errors = []

		// 	// se evalúa si hay un monto a entregar
		// 	if (
		// 		numeral(this.localData.cash).value() < 1 &&
		// 		numeral(this.localData.bank_terminal_credit).value() < 1 &&
		// 		numeral(this.localData.bank_terminal_credit_3).value() < 1 &&
		// 		numeral(this.localData.bank_terminal_debit).value() < 1 &&
		// 		numeral(this.localData.bank_transfer).value() < 1 &&
		// 		numeral(this.localData.paypal).value() < 1 &&
		// 		numeral(this.localData.mercadopago).value() < 1 &&
		// 		numeral(this.localData.aplazo).value() < 1
		// 	) {
		// 		errors.push('- Ingresa al menos un monto por método de pago')
		// 	}

		// 	if (errors.length) {
		// 		Swal.fire({
		// 			title: 'Ingresos',
		// 			html: errors.join('<br>'),
		// 			icon: 'warning',
		// 			confirmButtonText: 'Ok',
		// 		})
		// 		return false
		// 	}

		// 	let payload = {
		// 		cart: false,
		// 		client: null,
		// 		payment: {
		// 			...this.localData,
		// 			transfer: this.localData.bank_transfer,
		// 			bank_terminal: numeral(this.localData.bank_terminal_credit).value() + numeral(this.localData.bank_terminal_debit).value() + numeral(this.localData.bank_terminal_credit_3).value(),
		// 		},
		// 		service_order: null,
		// 		total:
		// 			numeral(this.localData.cash).value() +
		// 			numeral(this.localData.aplazo).value() +
		// 			numeral(this.localData.bank_terminal_credit).value() +
		// 			numeral(this.localData.bank_terminal_debit).value() +
		// 			numeral(this.localData.bank_terminal_credit_3).value() +
		// 			numeral(this.localData.bank_transfer).value() +
		// 			numeral(this.localData.paypal).value() +
		// 			numeral(this.localData.mercadopago).value(),
		// 		set_advance: false,
		// 		advances: [],
		// 		bankTerminalOperations: null,
		// 		salesman: null,
		// 		orderDiscount: 0,
		// 		discountsByArea: 0,
		// 		fromPacific: true,
		// 		bankTerminalOperations: [],
		// 		posFrom: this.localData.type,
		// 	}

		// 	if (numeral(this.localData.bank_terminal_credit).value() > 0) {
		// 		payload.bankTerminalOperations.push({
		// 			cardHolder: '',
		// 			cardType: 'credit',
		// 			bankingInstitution: '',
		// 			ending: '',
		// 			approval: '',
		// 			amount: numeral(this.localData.bank_terminal_credit).value(),
		// 		})
		// 	}

		// 	if (numeral(this.localData.bank_terminal_credit_3).value() > 0) {
		// 		payload.bankTerminalOperations.push({
		// 			cardHolder: '',
		// 			cardType: 'credit',
		// 			bankingInstitution: '',
		// 			ending: '',
		// 			approval: '',
		// 			msi: true,
		// 			amount: numeral(this.localData.bank_terminal_credit_3).value(),
		// 		})
		// 	}

		// 	if (numeral(this.localData.bank_terminal_debit).value() > 0) {
		// 		payload.bankTerminalOperations.push({
		// 			cardHolder: '',
		// 			cardType: 'debit',
		// 			bankingInstitution: '',
		// 			ending: '',
		// 			approval: '',
		// 			amount: numeral(this.localData.bank_terminal_debit).value(),
		// 		})
		// 	}

		// 	this.$store.dispatch('warehouse/CHECKOUT', payload).then(async (response) => {

		// 		this.onCloseModal('boutique')
		// 	})
		// },
		cleanData() {
			this.customer_type = ''
			this.searchText = ''
			this.cart = []
			this.client = {}
			this.selectedProduct = {}
			this.ticketData = {}
			this.paymentData = {}
			this.setAdvance = false
			this.advances = []
			this.totalByArea = {
				boutique: 0,
				servicio: 0,
				refacciones: 0,
			}
			this.discountsByArea = {
				boutique: 0,
				servicio: 0,
				refacciones: 0,
			}
			this.$store.dispatch('products/SET_STATE', { productsList: [] })
			this.posFrom = 'rls'
		},
		onCancel() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas cancelar la venta?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5222e',
				confirmButtonText: 'Sí, cancelar',
				cancelButtonColor: '#41b883',
				cancelButtonText: 'No, continuar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.cleanData()
				}
			})
		},
		onSearch() {
			if (this.searchText == '' || this.searchText.length < 3) {
				Swal.fire({
					title: 'Caja',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('products/SEARCH', { searchText: this.searchText, fromPOS: true })
			}
		},
		getPrice(string) {
			if (!string) {
				return 0
			}
			let price = JSON.parse(string)
			if (this.customer_type == 's2r') {
				return numeral(price.s2r_price).value()
			} else {
				return numeral(this.hasClient ? price.supplier_price : price.sale_price).value()
			}
		},
		onSelectProduct(product) {
			let quantity = utilities.objectValidate(product, 'quantity', 1)
			let sale_price = Number(numeral(this.getPrice(product.prices)).value())

			if (!sale_price) {
				Swal.fire({
					title: 'Atención',
					html: `El producto no cuenta con precio para el tipo de venta`,
					icon: 'warning',
					showCancelButton: false,
				})
				return false
			}

			// validamos si hay promoción por aplicar al precio de lista
			if (!!product.wc_discount) {
				sale_price = sale_price - sale_price * (product.wc_discount / 100)
			}
			let amount = sale_price * quantity
			let index = this.cart.findIndex((e) => e.id == product.id)

			if (index == -1) {
				this.cart.unshift({
					product,
					id: product.id,
					label: `${quantity} x ${product.label.toUpperCase()}`,
					sale_price_label: numeral(sale_price).format('$0,0.00'),
					sale_price,
					quantity,
					amount_label: numeral(amount).format('$0,0.00'),
					amount,
					wc_discount: product.wc_discount,
				})
			} else {
				let allCart = _.cloneDeep(this.cart)
				this.cart = []
				let cart = _.cloneDeep(allCart[index])
				quantity = cart.quantity + 1
				amount = cart.sale_price * quantity

				cart = {
					...cart,
					label: `${quantity} x ${product.label.toUpperCase()}`,
					sale_price_label: numeral(sale_price).format('$0,0.00'),
					sale_price,
					quantity,
					amount_label: numeral(amount).format('$0,0.00'),
					amount,
					wc_discount: product.wc_discount,
				}

				allCart[index] = _.cloneDeep(cart)
				this.cart = allCart
			}
			this.searchText = ''
			this.onCloseModal('products')
		},
		onDeleteProduct(id, requireConfirmation = true) {
			let index = this.cart.findIndex((e) => e.id == id)
			let allCart = _.cloneDeep(this.cart)

			if (requireConfirmation) {
				Swal.fire({
					title: 'Atención',
					html: `¿Deseas eliminar <b>${allCart[index].label}</b>?`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, continuar',
					cancelButtonText: 'Cancelar',
				}).then((result) => {
					if (result.isConfirmed) {
						this.cart = []
						allCart.splice(index, 1)
						this.cart = _.cloneDeep(allCart)
					}
				})
			} else {
				this.cart = []
				allCart.splice(index, 1)
				this.cart = _.cloneDeep(allCart)
			}
		},
		onChangePrice(id) {
			let index = this.cart.findIndex((e) => e.id == id)
			let cart = _.cloneDeep(this.cart[index])
			let theProduct = {
				...cart,
			}

			theProduct.sale_price = numeral(theProduct.sale_price).format('0,0.00')
			theProduct.normal_price = numeral(theProduct.product.normal_price).format('0,0.00')
			theProduct.label = theProduct.label.replace(`${theProduct.quantity} x `, '')

			this.onShowModal('adjust')

			setTimeout(() => {
				this.selectedProduct = _.cloneDeep(theProduct)
			}, 100)
		},
		adjustProduct() {
			if (this.setAdvance && (this.selectedProduct.label == '' || this.selectedProduct.label.length < 23)) {
				Swal.fire({
					title: 'Caja',
					text: 'Debes ingresar el motivo del anticipo',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			let quantity = this.selectedProduct.quantity
			let amount = numeral(this.selectedProduct.sale_price).value() * Number(quantity)
			let index = this.cart.findIndex((e) => e.id == this.selectedProduct.id)
			let allCart = _.cloneDeep(this.cart)
			this.cart = []
			let cart = _.cloneDeep(allCart[index])

			cart = {
				...cart,
				label: `${quantity} x ${this.selectedProduct.label}`,
				sale_price_label: numeral(this.selectedProduct.sale_price).format('$0,0.00'),
				sale_price: numeral(this.selectedProduct.sale_price).value(),
				quantity,
				amount_label: numeral(amount).format('$0,0.00'),
				amount,
			}

			allCart[index] = cart
			this.cart = _.cloneDeep(allCart)
			this.searchText = ''

			if (this.setAdvance) {
				this.onShowModal('client')
			}

			setTimeout(() => {
				this.onCloseModal('adjust')
			}, 100)
		},
		closePayment() {
			this.modal.payment.visible = false
			this.cleanData()
		},
		async onCloseModal(section) {
			switch (section) {
				case 'products':
					this.selectedProduct = {}
					this.modal[section].visible = false
					break
				case 'serviceOrders':
					this.modal[section].visible = false
					break
				case 'quickSale':
					//
					this.modal[section].visible = false
					break
				case 'addClient':
					this.modal[section].visible = false
					break
				case 'client':
					this.modal[section].visible = false
					this.customer_type = 'general'
					//
					// if (this.setAdvance && !utilities.objectValidate(this.client, 'id', false)) {
					// 	await Swal.fire({
					// 		title: 'Atención',
					// 		html: `Se va a cancelar la recepción del anticipo. ¿Deseas continuar?`,
					// 		icon: 'warning',
					// 		showCancelButton: true,
					// 		confirmButtonColor: '#f5222e',
					// 		confirmButtonText: 'Sí, cancelar',
					// 		cancelButtonColor: '#41b883',
					// 		cancelButtonText: 'No, continuar',
					// 	}).then((result) => {
					// 		if (result.isConfirmed) {
					// 			this.cleanData()
					// 			this.modal[section].visible = false
					// 		}
					// 	})
					// } else {
					// 	this.modal[section].visible = false
					// 	this.onShowModal('payment')
					// }
					break
				case 'adjust':
					if (this.setAdvance && !this.modal.client.visible) {
						await Swal.fire({
							title: 'Atención',
							html: `Se va a cancelar la recepción del anticipo. ¿Deseas continuar?`,
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: '#f5222e',
							confirmButtonText: 'Sí, cancelar',
							cancelButtonColor: '#41b883',
							cancelButtonText: 'No, continuar',
						}).then((result) => {
							if (result.isConfirmed) {
								this.cleanData()
								this.modal[section].visible = false
								this.selectedProduct = {}
							}
						})
					} else {
						this.modal[section].visible = false
						this.selectedProduct = {}
					}
					break
				case 'payment':
					await Swal.fire({
						title: 'Atención',
						html: `Se va a cancelar la recepción del pago. ¿Deseas continuar?`,
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#f5222e',
						confirmButtonText: 'Sí, cancelar',
						cancelButtonColor: '#41b883',
						cancelButtonText: 'No, continuar',
					}).then((result) => {
						if (result.isConfirmed) {
							this.modal[section].visible = false
							if (this.setAdvance) {
								this.cleanData()
							}
						}
					})
					break
				case 'boutique':
					this.localData = _.cloneDeep(cashFlowModel.cashFlowStructure)
					this.modal[section].visible = false

					break
				default:
					this.modal[section].visible = false
					break
			}
		},
		async validateAreas() {
			// Validación de área en todas las partidas
			return new Promise((resolve, reject) => {
				let errors = []
				this.totalByArea = {
					boutique: 0,
					servicio: 0,
					refacciones: 0,
				}

				this.cart.forEach((element) => {
					// console.log('element-->', element)
					let quantity = numeral(element.quantity).value()
					let amount = numeral(element.sale_price).value()

					// Validamos el área por partida y añadimos a totales locales
					// para evaluar en caso de haber descuentos
					if (element.area == '') {
						errors.push(element.label)
					} else {
						this.totalByArea[element.area] += quantity * amount
					}
				})

				if (errors.length) {
					Swal.fire({
						title: 'Falta asignación de área de cobro',
						html: errors.join('<br>'),
						icon: 'warning',
						confirmButtonText: 'Ok',
					})
					reject()
				}
				resolve()
			})
		},
		async onShowModal(section, posFrom = '') {
			this.validateAreas().then(async () => {
				if (section == 'payment' && this.setAdvance && !this.hasClient) {
					Swal.fire({
						title: 'Caja',
						text: 'Debes solicitar datos del cliente para recibir un anticipo',
						icon: 'warning',
						confirmButtonText: 'Ok',
					})
					return false
				}

				// Validamos si se aplica un descuento
				if (this.orderDiscount) {
					await Swal.fire({
						title: 'Caja',
						text: 'Ingresa clave de autorización para aplicar descuento',
						input: 'password',
						showCancelButton: true,
						reverseButtons: true,
						confirmButtonColor: '#41b883',
						confirmButtonText: 'Continuar',
						cancelButtonColor: '#f5222e',
						cancelButtonText: 'Cancelar',
						inputAttributes: {
							autocorrect: 'off',
							autocomplete: 'off',
						},
						inputValidator: (value) => {
							return new Promise((resolve) => {
								if (!value) {
									resolve('Ingresa clave de autorización')
								}
								resolve()
							})
						},
					}).then(async (result) => {
						if (result.isConfirmed) {
							//
							await this.$store
								.dispatch('pos/REQUEST_AUTHORIZATION', {
									password: result.value,
								})
								.then(() => {
									this.modal[section].visible = true
									this.posFrom = posFrom
								})
								.catch((message) => {
									Swal.fire({
										title: 'Caja',
										text: message,
										icon: 'warning',
										confirmButtonText: 'Ok',
									}).then(() => {
										this.onShowModal('payment')
									})
								})
						}
					})
				} else {
					this.modal[section].visible = true
					this.posFrom = posFrom
				}
			})
		},
		addClient() {
			//
			this.$refs.clientsQuickSearchComponent.onSelectedRecord()
		},
		onSelectedClient(payload) {
			// console.log('onSelectedClient-->', payload);
			this.client = _.cloneDeep(payload)
			this.onCloseModal('client')
			this.onCloseModal('addClient')
		},
		onAddClient() {
			// this.onCloseModal('client')
			this.onShowModal('addClient')
		},
		onCleanClient() {
			this.client = {}
		},
		printTicket() {
			this.$htmlToPaper('posTicket')
		},
		cleanServiceOrder() {
			this.serviceOrder = 0
			if (utilities.objectValidate(this.actualOutboundOrder, 'order', false)) {
				let order = JSON.parse(this.actualOutboundOrder.order)
				order.forEach((e) => {
					this.onDeleteProduct(e.id, false)
				})
			}
		},
		async doSearch() {
			await this.$store.dispatch('serviceOrders/GET_FINISHED_SERVICE_ORDERS')
			this.modal.serviceOrders.visible = true
		},
		getOutboundOrder() {
			this.$store.dispatch('products/GET_OUTBOUND_ORDER', {
				id: this.serviceOrderSelected,
				onlyLoad: true,
			})
			this.modal.serviceOrders.visible = false
			this.serviceOrderSelected = ''
		},
		async onSearchODS() {
			if (this.serviceOrder > 0) {
				await Swal.fire({
					title: 'Atención',
					html: `Ya se encuentran cargados los productos de la <b>ODS #${this.serviceOrder}</b>. Al continuar, se eliminarán y se cargarán los nuevos. ¿Deseas continuar?`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, continuar',
					cancelButtonText: 'Cancelar',
				}).then((result) => {
					if (result.isConfirmed) {
						this.cleanServiceOrder()
						this.doSearch()
					}
				})
			} else {
				this.doSearch()
			}
		},
		checkOut() {
			this.$refs.paymentComponent.onChekOut()
		},
		onCloseSale(payment) {
			this.paymentData = _.cloneDeep(payment)
			let localAdvances = 0
			if (this.advances.length) {
				this.advances.forEach((element) => {
					localAdvances += element.total
				})
			}
			let cash = Number(
				numeral(this.total).value() -
					numeral(localAdvances).value() -
					numeral(payment.bank_terminal).value() -
					numeral(payment.transfer).value() -
					numeral(payment.paypal).value() -
					numeral(payment.mercadopago).value() -
					numeral(payment.billpocket).value() -
					numeral(this.orderDiscount).value(),
			)

			let bankTerminalOperations = payment.bankTerminalOperations.map((e) => {
				if (e.cardType == 'credit_3msi') {
					e = {
						...e,
						cardType: 'credit',
						msi: true,
					}
				}
				return e
			})

			let payload = {
				cart: this.cart,
				client: utilities.objectValidate(this.client, 'id', null),
				payment: {
					...payment,
					cash,
				},
				service_order: this.serviceOrder,
				total: numeral(this.total).value() - numeral(this.orderDiscount).value(),
				set_advance: this.setAdvance,
				advances: this.advances,
				bankTerminalOperations,
				salesman: payment.salesman,
				orderDiscount: numeral(this.orderDiscount).value(),
				discountsByArea: this.discountsByArea,
				posFrom: this.posFrom,
				customerType: this.customer_type,
			}

			this.$store.dispatch('warehouse/CHECKOUT', payload).then((response) => {
				this.ticketData = _.cloneDeep(response.data)
				setTimeout(() => {
					this.printTicket()
				}, 100)
			})
		},
		onDeletePriceAdjustment() {
			this.priceAdjustment = 0
		},
		onDeleteAdvance(index) {
			this.advances.splice(index, 1)
		},
		onDeleteServiceOrder() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar los productos de la orden de servicio de la venta?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.cleanServiceOrder()
				}
			})
		},
		onCreateClient() {
			document.getElementById('clientDetailGeneralComponent_submit').click()
		},
		onGetAdvance() {
			Swal.fire({
				title: 'Ingresa monto a recibir',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!isNaN(value)) {
							if (!value || value.length < 0) {
								resolve('Es necesario ingresar monto a recibir')
							}
						} else {
							resolve('Es necesario ingresar monto correcto')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					let label = `1 x Anticipo por `
					this.cart.unshift({
						product: {
							sku: 'anticipo',
							label,
							normal_price: numeral(result.value).value(),
						},
						id: moment().valueOf(),
						label,
						sale_price_label: numeral(result.value).format('$0,0.00'),
						sale_price: numeral(result.value).value(),
						quantity: 1,
						amount_label: numeral(result.value).format('$0,0.00'),
						amount: numeral(result.value).value(),
					})

					this.setAdvance = true

					this.onChangePrice(this.cart[0].id)
				}
			})
		},
		onSetAdvance() {
			Swal.fire({
				title: 'Ingresa # de ticket',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!isNaN(value)) {
							if (!value || value.length < 1) {
								resolve('Es necesario ingresar número de ticket')
							}
						} else {
							resolve('Es necesario ingresar número de ticket correcto')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					let ticket = numeral(result.value).value()
					let findAdvance = this.advances.find((e) => e.ticket == ticket)

					if (!!utilities.objectValidate(findAdvance, 'ticket', false)) {
						Swal.fire({
							title: 'Caja',
							text: 'Este ticket ya ha sido asociado a la venta',
							icon: 'warning',
							confirmButtonText: 'Ok',
						}).then(() => {
							this.onSetAdvance()
						})
						return false
					}

					this.$store.dispatch('warehouse/GET_ADVANCE', ticket).then((response) => {
						this.advances.unshift({
							...response,
							ticket: result.value,
						})
					})
				}
			})
		},
		onSetLabor() {
			Swal.fire({
				title: 'Ingresa monto a cobrar',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				reverseButtons: true,
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!isNaN(value)) {
							if (!value || Number(value) < 1) {
								resolve('Es necesario ingresar un monto válido')
							}
						} else {
							resolve('Es necesario ingresar un monto válido')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					let label = `1 x Servicio Mecánico`
					this.cart.unshift({
						product: {
							sku: 'SER-MEC',
							label,
							normal_price: numeral(result.value).value(),
							sat_key: '78181500',
							measurement_unit: 'E48',
						},
						id: moment().valueOf(),
						label,
						sale_price_label: numeral(result.value).format('$0,0.00'),
						sale_price: numeral(result.value).value(),
						quantity: 1,
						amount_label: numeral(result.value).format('$0,0.00'),
						amount: numeral(result.value).value(),
						area: 'servicio',
					})
				}
			})
		},
		generateCashflow() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas realizar un corte de caja?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
				reverseButtons: true,
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('cashFlow/CREATE_BY_CASHIER')
					// this.$store.dispatch('cashFlow/CREATE_BY_CASHIER').then(async (response) => {
					// 	// generar ODC para pago de comisiones
					// 	let payload = {
					// 		total: 0,
					// 		elements: [],
					// 		supplier_id: 31,
					// 		payment_method_index: 0,
					// 		payment_method_data: {
					// 			id: 76,
					// 			supplier_id: 31,
					// 			payment_method: '2',
					// 			banking_institution: 2,
					// 			account_number: null,
					// 			clabe_number: null,
					// 			created_at: '2022-11-11T06:26:08.000Z',
					// 			updated_at: null,
					// 			bankName: 'BBVA Bancomer',
					// 		},
					// 		blocked: true,
					// 		general_discount: 0,
					// 		discount_type: '',
					// 		status: null,
					// 		area: 'general',
					// 		advance_ticket: 0,
					// 		reference: '',
					// 		destination: 'pacific',
					// 	}
					// 	let comission = 0.0225
					// 	let unit_cost = numeral(this.localData.bank_terminal_credit).value() * comission * 1.16
					// 	let theElement = {}

					// 	if (numeral(this.localData.bank_terminal_credit).value() > 0) {
					// 		unit_cost = numeral(this.localData.bank_terminal_credit).value() * comission * 1.16
					// 		theElement = {
					// 			quantity: 1,
					// 			measurement_unit: 'service',
					// 			sku: 'BBVA-CREDITO',
					// 			unit_cost,
					// 			link: '',
					// 			service_order: '',
					// 			description: 'Pago de comisión bancaria por uso de terminal - Tarjetas de Crédito',
					// 		}
					// 		payload.elements.push(theElement)
					// 		payload.total += unit_cost
					// 	}

					// 	if (numeral(this.localData.bank_terminal_debit).value() > 0) {
					// 		unit_cost = numeral(this.localData.bank_terminal_debit).value() * comission * 1.16
					// 		theElement = {
					// 			quantity: 1,
					// 			measurement_unit: 'service',
					// 			sku: 'BBVA-DEBITO',
					// 			unit_cost,
					// 			link: '',
					// 			service_order: '',
					// 			description: 'Pago de comisión bancaria por uso de terminal - Tarjetas de Débito',
					// 		}
					// 		payload.elements.push(theElement)
					// 		payload.total += unit_cost
					// 	}

					// 	if (numeral(this.localData.bank_terminal_credit_3).value() > 0) {
					// 		unit_cost = 0
					// 		theElement = {
					// 			quantity: 1,
					// 			measurement_unit: 'service',
					// 			sku: 'BBVA-3MSI',
					// 			unit_cost,
					// 			link: '',
					// 			service_order: '',
					// 			description: 'Pago de comisión bancaria por uso de terminal - Tarjetas de Crédito a 3 MSI',
					// 		}
					// 		payload.elements.push(theElement)
					// 		payload.total = 0
					// 		payload.blocked = 0
					// 	}

					// 	await this.$store.dispatch('purchaseOrders/CREATE', { payload })
					// })
				}
			})
		},
		onSelectServiceOrder(id) {
			this.serviceOrderSelected = id
		},
		showModalDiscount() {
			this.validateAreas().then(() => {
				this.modal.discounts.visible = true
			})
		},
		onDeleteDiscount(type) {
			this.discountsByArea[type] = 0
		},
		onSetDiscount() {
			document.getElementById('discountComponent_submit').click()
		},
		addDiscount(payload) {
			// console.log('payload-->', payload)
			let orderDiscount = numeral(payload.amount).value()

			this.validateAreas().then(() => {
				// console.log('this.totalByArea-->', this.totalByArea[payload.area])
				if (orderDiscount >= this.totalByArea[payload.area] || this.discountsByArea[payload.area] + orderDiscount >= this.totalByArea[payload.area]) {
					Swal.fire({
						title: 'Caja',
						text: 'El monto del descuento no puede dejar el total por área en cero',
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				} else {
					this.discountsByArea[payload.area] += orderDiscount
					this.onCloseModal('discounts')
				}
			})
		},
		addShipment() {
			Swal.fire({
				title: 'Caja',
				text: 'Ingresa el monto',
				input: 'text',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value || isNaN(value)) {
							resolve('Ingresa monto válido')
						}
						resolve()
					})
				},
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.cart.unshift({
						product: {
							label: 'GASTOS DE ENVÍO',
							sku: 'GSENV',
							sat_key: '01010101',
							measurement_unit: 'H87',
						},
						id: moment().valueOf(),
						label: `1 x GASTOS DE ENVÍO`,
						sale_price_label: numeral(result.value).format('$0,0.00'),
						sale_price: numeral(result.value).value(),
						quantity: 1,
						amount_label: numeral(result.value).format('$0,0.00'),
						amount: numeral(result.value).value(),
					})
				}
			})
		},
	},
	watch: {
		productsList: {
			deep: true,
			handler(newData) {
				if (newData.length == 1) {
					this.onSelectProduct(newData[0])
				}
				if (newData.length > 1) {
					this.modal.products.visible = true
				}
			},
		},
		actualOutboundOrder: {
			deep: true,
			handler(newData) {
				if (newData && utilities.objectValidate(newData, 'order', false)) {
					this.serviceOrder = newData.id
					let order = JSON.parse(newData.order)
					order.forEach((e) => {
						let payload = {
							...e.product,
							quantity: utilities.objectValidate(e, 'quantity', 1),
						}
						if (e.fromServiceOrder) {
							payload.fromServiceOrder = true
						}
						setTimeout(() => {
							this.onSelectProduct(payload)
						}, 10)
					})
				}
			},
		},
		total(value) {
			if (value == 0) {
				this.onDeletePriceAdjustment()
				this.cleanServiceOrder()
			}
		},
		customer_type(newValue) {
			if (newValue == 'supplier') {
				this.onShowModal('client')
			}
		},
	},
}
</script>
