<template>
	<div>
		<div id="cashflowTicket" align="center" v-show="false">
			<table border="0" width="450">
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">*****************************************</div>
						<div class="txt3">Steel Backbone</div>
						<div class="txt3">*****************************************</div>
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">Corte de caja</div>
						<div class="txt3">=========================================</div>
					</td>
				</tr>
				<tr>
					<td width="50%" align="center" class="txt2">
						<b>Fecha y hora</b>
						<br />
						{{ ticketData.created_at }} hrs.
					</td>
					<td width="50%" align="center" class="txt2">
						<b>Total</b>
						<br />
						<b>{{ numeral(totalCashflow).format('$0,0.00') }}</b>
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">=========================================</div>
					</td>
				</tr>
			</table>
			<table border="0" width="450">
				<tr>
					<td align="center" class="txt2">
						<b>=== Ingresos ===</b>
					</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Efectivo:</b> {{ numeral(ticketData?.pacific?.cash?.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Tarjeta de crédito:</b> {{ numeral(ticketData?.pacific?.credit?.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Tarjeta de débito:</b> {{ numeral(ticketData?.pacific?.debit?.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>Transferencia:</b> {{ numeral(ticketData?.pacific?.transfer?.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>PayPal:</b> {{ numeral(ticketData?.pacific?.paypal?.total).format('$0,0.00') }}</td>
				</tr>
				<tr>
					<td align="right" class="txt3 p07"><b>MercadoPago:</b> {{ numeral(ticketData?.pacific?.mercadopago?.total).format('$0,0.00') }}</td>
				</tr>
			</table>
			<table border="0" width="450" class="pt20" v-if="ticketData.showPanelAmount">
				<tr>
					<td align="center">
						<div class="txt3">*****************************************</div>
					</td>
				</tr>
			</table>
			<table border="0" width="450" style="margin-top: 1.5cm">
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">*****************************************</div>
						<div class="txt3">Resumen de depósitos</div>
						<div class="txt3">*****************************************</div>
					</td>
				</tr>
				<tr>
					<td align="center" colspan="3">
						<div class="txt3">XXXXXXXX</div>
						<div class="txt3">{{ numeral(ticketData?.pacific?.cash?.total).format('$0,0.00') }}</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import numeral from 'numeral'
import VueHtmlToPaper from 'vue-html-to-paper'
import utilities from '@/services/utilities'
import moment from 'moment'

const options = {
	name: '_blank',
	specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
	styles: ['resources/css/printTickets.css'],
	timeout: 1000, // default timeout before the print window appears
	autoClose: true, // if false, the window will not close after printing
	windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

export default {
	name: 'cashflowTicketComponent',
	computed: {
		...mapGetters('cashFlow', ['ticketData']),
		...mapGetters('branchOffices', ['branchOfficesList']),
		...mapGetters('user', ['user']),
		branchOffice() {
			return this.branchOfficesList.find((e) => e.id == this.user.branch_office_id)
		},
	},
	data() {
		return {
			ticketVisible: false,
			totalCashflow: 0,
		}
	},
	methods: {
		numeral,
		moment,
	},
	watch: {
		ticketData(newData) {
			if (utilities.objectValidate(newData, 'cash_flow_id', false)) {
				this.ticketVisible = true
				this.totalCashflow =
					utilities.objectValidate(newData, 'rls.credit.total', 0) +
					utilities.objectValidate(newData, 'rls.debit.total', 0) +
					utilities.objectValidate(newData, 'rls.mercadopago.total', 0) +
					utilities.objectValidate(newData, 'rls.paypal.total', 0) +
					utilities.objectValidate(newData, 'rls.transfer.total', 0) +
					utilities.objectValidate(newData, 'rls.cash.total', 0)

				setTimeout(() => {
					this.$htmlToPaper('cashflowTicket')
				}, 500)

				setTimeout(() => {
					this.ticketVisible = false
				}, 3000)
			}
		},
	},
}
</script>
